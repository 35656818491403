import { styled } from "@vertigis/react-ui/styles"
import ListItemButton, {
  listItemButtonClasses,
} from "@vertigis/react-ui/ListItemButton"

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  background: theme.specialColors.darkbg,
  ["&:hover"]: {
    background: "unset",
    backgroundColor: theme.specialColors.darkbg,
  },
  [`&.${listItemButtonClasses.selected}`]: {
    background: "unset",
    backgroundColor: theme.specialColors.darkbg,
  },
}))

export default StyledListItemButton
