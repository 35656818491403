import CustomDrawer from "../CustomDrawer"

export const APPLICATIONS = {
  explorer: {
    label: "LM Explorer",
    iconSrc: "/assets/explorer.svg",
    description:
      "Stellen Sie Ihre Daten der Verwaltung, der Wirtschaft und den Bürger*innen bereit und erfüllen Sie damit Ihren gesetzlichen Auftrag als Teil der Daseinsvorsorge zeitgemäß. Vertreiben Sie Ihre Daten in Form amtlicher Produkte bequem in einem Shopsystem.",
  },
  server: {
    label: "LM Server",
    iconSrc: "/assets/server.svg",
    description:
      "Verwalten Sie Ihre Fachdaten in einer zentralen Datenbank mit automatischen Qualitätsprüfungen und führen Sie damit Ihre Geobasisdaten gemäß internationalen und nationalen Standards.",
  },
  editor: {
    label: "LM Editor",
    iconSrc: "/assets/editor.svg",
    description:
      "Erheben und bearbeiten Sie Ihre Landmanagement-Daten gemäß nationaler und internationaler Standards. Arbeiten Sie effizient und sichern Sie die hohe Qualität Ihrer Fachdaten.",
  },
  dataConverter: {
    label: "Data converter",
    iconSrc: "/assets/dataconverter.svg",
    description:
      "Konvertieren Sie Ihre Geobasisdaten in alle gängigen Geodatenformate und stellen Sie diese anderen Nutzer*innen zur Verfügung. Erleichtern Sie sich damit den Austausch Ihrer Daten und beschleunigen Sie Ihre Verfahren und Kommunikationswege.",
  },
}

const Navigation: React.FC = () => {
  const items = Object.keys(APPLICATIONS).map((k) => {
    const key = k as keyof typeof APPLICATIONS
    return {
      key,
      ...APPLICATIONS[key],
    }
  })

  return <CustomDrawer items={items} />
}

export default Navigation
