import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Service, ServiceJson } from "./types"
import axios from "axios"
import { RootState } from "../../app/store"

const PREFIX = "services"

const prefix = (str: string) => `${PREFIX}/${str}`

export type ServiceState = {
  services: Service[]
}

const initialState: ServiceState = {
  services: [],
}

export const fetchServices = createAsyncThunk(prefix("get"), async () => {
  const response = await axios.get<ServiceJson>(
    window.__RUNTIME_CONFIG__.VITE_APP_JSON_URL,
  )
  return response.data.services
})

const serviceSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchServices.fulfilled, (state, action) => {
      state.services = action.payload
    })
  },
})

export const selectServices = (state: RootState) => state.services.services

// export const {} = serviceSlice.actions

export default serviceSlice.reducer
