import Grid from "@vertigis/react-ui/Grid"
import { useAppSelector } from "../../app/hooks"
import { selectServices } from "../../features/services/serviceSlice"
import { useParams } from "react-router-dom"
import { useMemo } from "react"
import { APPLICATIONS } from "../Nav/Navigation"
import ServiceGridCard from "./ServiceGridCard"
import Box from "@vertigis/react-ui/Box"

const ServiceGrid: React.FC = () => {
  const services = useAppSelector(selectServices)

  const params = useParams()

  const projectId = useMemo(() => {
    return (params.projectId ?? "explorer") as keyof typeof APPLICATIONS
  }, [params])

  const relevantServices = useMemo(() => {
    return services.filter(
      (s) =>
        s.projects.includes(projectId) &&
        (window.__RUNTIME_CONFIG__.VITE_APP_DISPLAY_ADDITIONAL_INFO ===
          "true" ||
          s.category === "app"),
    )
  }, [services, projectId])

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {relevantServices.map((rs) => {
          return (
            <Box
              key={rs.name}
              sx={{ marginBottom: "50px", marginRight: "50px" }}
            >
              <ServiceGridCard rs={rs} />
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default ServiceGrid
