import { useEffect } from "react"
import { useAppDispatch } from "./app/hooks"
import { fetchServices } from "./features/services/serviceSlice"
import Navigation from "./components/Nav/Navigation"
import Box from "@vertigis/react-ui/Box"
import { Outlet } from "react-router-dom"
import { styled, useTheme } from "@vertigis/react-ui/styles"
import Typography, { typographyClasses } from "@vertigis/react-ui/Typography"
import Link from "@vertigis/react-ui/Link"
import IconButton from "@vertigis/react-ui/IconButton"
import UserIcon from "@vertigis/react-ui/icons/User"
import StyledTooltip from "./components/styled/StyledTooltip"
import CustomTitle from "./components/CustomTitle"
import StyledInfoLinkList from "./components/styled/StyledInfoLink"

const TopBar = styled(Box)(({ theme }) => ({
  minHeight: "75px",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}))

const SocialBadge = styled(Box)({
  margin: "5px",
  marginInline: "15px",
  transition: "all .2s ease-in-out",
  ["&:hover"]: {
    transform: "scale(1.2)",
  },
})

function App() {
  const dispatch = useAppDispatch()

  console.log("Runtime config: ", window.__RUNTIME_CONFIG__)

  useEffect(() => {
    dispatch(fetchServices())
  }, [])

  const theme = useTheme()

  return (
    <Box sx={{ display: "flex" }}>
      <Navigation />
      <Box
        sx={{
          width: "100%",
          marginLeft: "200px",
        }}
        component="main"
      >
        <TopBar
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <Box
            sx={{
              paddingLeft: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              color: theme.palette.text.secondary,
            }}
          >
            <Box sx={{ marginRight: "50px" }}>
              <Typography>
                Willkommen in der Umgebung{" "}
                {window.__RUNTIME_CONFIG__.VITE_APP_NAMESPACE}
              </Typography>
            </Box>
            <StyledTooltip
              title={
                <Box>
                  <Typography
                    variant="h1"
                    sx={{
                      fontSize: "x-large",
                      color: theme.palette.primary.main,
                      textDecoration: "underline",
                    }}
                  >
                    Disclaimer
                  </Typography>
                  <Typography>
                    Please note that this is a demo version of the Land
                    Management solution which is still undergoing development.
                    The software and all content found on this demo are provided
                    on an “as is” and “as available” basis.
                  </Typography>
                  <Typography>
                    VertiGIS does not give any warranties, whether express or
                    implied, as to the suitability or usability of the website,
                    its software or any of its content.
                  </Typography>
                  <Typography>
                    Should you encounter any bugs, glitches, lack of
                    functionality or other problems in the demo, please let us
                    know immediately so we can rectify these accordingly.
                  </Typography>
                  <Typography>
                    Your help in this regard is greatly appreciated! You can
                    write to us at this address public-sector@vertigis.com.
                  </Typography>
                </Box>
              }
            >
              <Box>
                <Typography
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  Disclaimer
                </Typography>
              </Box>
            </StyledTooltip>
          </Box>
          <Box sx={{ mr: "50px" }}>
            <StyledTooltip title="Login coming soon">
              <Box>
                <IconButton disabled>
                  <UserIcon sx={{ color: "white!important" }} />
                </IconButton>
              </Box>
            </StyledTooltip>
          </Box>
        </TopBar>
        <CustomTitle />
        <Box sx={{ padding: "50px", backgroundColor: "#ededed" }}>
          <Outlet />
        </Box>
        <Box
          sx={{
            width: "100%",
            minHeight: "300px",
            background: " linear-gradient(0deg, #01292A, #0B1622)",
          }}
        >
          <Box
            sx={{
              width: "100%",
              minHeight: "300px",
              backgroundImage: "url('/assets/streets-overlay.png')",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: theme.palette.text.secondary,
                whiteSpace: "nowrap",
              }}
            >
              <Box
                sx={{
                  mt: "50px",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  justifyContent: "center",
                }}
              >
                <Box
                  component="img"
                  alt="logo-footer"
                  src="/assets/VertiGIS-Logo-White.svg"
                  sx={{
                    maxHeight: "2rem",
                    m: "25px",
                  }}
                />
                <StyledInfoLinkList
                  header={{
                    label: "Support & Services",
                    link: "https://www.vertigis.com/support-services/",
                  }}
                  elements={[
                    {
                      label: "Support",
                      link: "https://www.vertigis.com/support-services/support/",
                    },
                    {
                      label: "Services",
                      link: "https://www.vertigis.com/support-services/services/",
                    },
                    {
                      label: "Training",
                      link: "https://www.vertigis.com/training/",
                    },
                  ]}
                />
                <StyledInfoLinkList
                  header={{
                    label: "VertiGIS Demos",
                  }}
                  elements={[
                    {
                      label: "VertiGIS Studio Demo",
                      link: "https://demo.vertigisstudio.com/portal/home/",
                    },
                    {
                      label: "VertiGIS Networks Demo",
                      link: "https://vn-demo.vertigis.com/portal/home/",
                    },
                  ]}
                />
                <StyledInfoLinkList
                  header={{
                    label: "More Information",
                  }}
                  elements={[
                    {
                      label: "www.vertigis.com",
                      link: "https://www.vertigis.com",
                    },
                    {
                      label: "Public Sector",
                      link: "https://www.vertigis.com/industries/public-sector/",
                    },
                    {
                      label: "Contact",
                      link: "https://www.vertigis.com/contact-us/",
                    },
                  ]}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  mb: "30px",
                }}
              >
                <a href="https://www.facebook.com/VertiGIS/" target="_blank">
                  <SocialBadge
                    component="img"
                    // @ts-ignore
                    alt="facebook"
                    src="/assets/facebook.svg"
                  />
                </a>
                <a href="https://twitter.com/Vertigis" target="_blank">
                  <SocialBadge
                    component="img"
                    // @ts-ignore
                    alt="twitter"
                    src="/assets/twitter.svg"
                  />
                </a>
                <a
                  href="https://www.instagram.com/vertigislife/"
                  target="_blank"
                >
                  <SocialBadge
                    component="img"
                    // @ts-ignore
                    alt="instagram"
                    src="/assets/instagram.svg"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/vertigis"
                  target="_blank"
                >
                  <SocialBadge
                    component="img"
                    // @ts-ignore
                    alt="linked"
                    src="/assets/linked.svg"
                  />
                </a>
              </Box>
              <Box
                sx={{
                  mb: "50px",
                }}
              >
                © 2023 VertiGIS. All rights reserved. |{" "}
                <Box
                  component="a"
                  href="https://www.vertigis.com/privacy-center"
                  target="_blank"
                  sx={{
                    color: theme.palette.info.main,
                    marginBottom: "25px",
                    transition: "color .3s",
                    textDecoration: "none",
                    ["&:hover"]: {
                      color: theme.palette.primary.main,
                      cursor: "pointer",
                    },
                  }}
                >
                  Privacy center
                </Box>{" "}
                |{" "}
                <Box
                  component="a"
                  href="https://www.vertigis.com/imprint"
                  target="_blank"
                  sx={{
                    color: theme.palette.info.main,
                    marginBottom: "25px",
                    transition: "color .3s",
                    textDecoration: "none",
                    ["&:hover"]: {
                      color: theme.palette.primary.main,
                      cursor: "pointer",
                    },
                  }}
                >
                  Imprint
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default App
