import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./app/store"
import App from "./App"
import "./index.css"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import Home from "./components/Home/Home"
import ServiceGrid from "./components/ServiceGrid/ServiceGrid"
import { createTheme, GcxThemeProvider } from "@vertigis/react-ui/styles"

declare module "@vertigis/react-ui/styles" {
  interface Theme {
    specialColors: {
      darkbg: React.CSSProperties["color"]
    }
  }

  interface ThemeOptions {
    specialColors: {
      darkbg: React.CSSProperties["color"]
    }
  }
}

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/:projectId",
        element: <ServiceGrid />,
      },
    ],
  },
])

const theme = createTheme({
  specialColors: {
    darkbg: "#0F1A26",
  },
  palette: {
    primary: {
      main: "#0E773C",
      dark: "#0E773C",
    },
    background: {
      default: "rgba(255, 255, 255)",
    },
    text: {
      primary: "#000",
      secondary: "white",
    },
    info: {
      main: "#9FCC3B",
    },
  },
  typography: {
    fontFamily: [
      "Inter-Regular",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Roboto"',
      '"Oxygen"',
      '"Ubuntu"',
      '"Cantarell"',
      '"Fira Sans"',
      '"Droid Sans"',
      '"Helvetica Neue"',
      "sans-serif",
    ].join(","),
  },
})

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <GcxThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </GcxThemeProvider>
    </Provider>
  </React.StrictMode>,
)
