import Tooltip, {
  TooltipProps,
  tooltipClasses,
} from "@vertigis/react-ui/Tooltip"
import { styled } from "@vertigis/react-ui/styles"

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    backgroundColor: "white",
    color: "black",
    padding: "10px",
  },
})

export default StyledTooltip
