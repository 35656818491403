import Box from "@vertigis/react-ui/Box"
import Typography, { typographyClasses } from "@vertigis/react-ui/Typography"
import { styled } from "@vertigis/react-ui/styles"

interface StyledInfoLinkListProps {
  header: {
    link?: string
    label: string
  }
  elements: {
    link?: string
    label: string
  }[]
}

interface LinkProps {
  noLink?: boolean
}

const InfoTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "noLink",
})<LinkProps>(({ theme, noLink }) => ({
  [`&.${typographyClasses.root}`]: {
    color: theme.palette.info.main,
    fontSize: "large",
    marginBottom: "25px",
    transition: "color .3s",
  },
  ["&:hover"]: noLink
    ? {}
    : {
        color: theme.palette.primary.main,
        cursor: "pointer",
      },
}))

const InfoLink = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "noLink",
})<LinkProps>(({ theme, noLink }) => ({
  [`&.${typographyClasses.root}`]: {
    marginBottom: "10px",
    color: theme.palette.text.secondary,
    transition: "color .3s",
  },
  ["&:hover"]: noLink
    ? { cursor: "unset" }
    : {
        color: theme.palette.info.main,
        cursor: "pointer",
      },
}))

const StyledInfoLinkList: React.FC<StyledInfoLinkListProps> = (props) => {
  const { header, elements } = props
  return (
    <Box sx={{ m: "25px" }}>
      <InfoTitle noLink={!header.link}>
        <Box
          component="a"
          href={header.link}
          target="blank"
          sx={{
            textDecoration: "none",
            color: "inherit",
          }}
        >
          {header.label}
        </Box>
      </InfoTitle>
      {elements.map((el) => (
        <InfoLink key={el.label} noLink={!el.link}>
          <Box
            component="a"
            href={el.link}
            target="blank"
            sx={{
              textDecoration: "none",
              color: "inherit",
            }}
          >
            {el.label}
          </Box>
        </InfoLink>
      ))}
    </Box>
  )
}

export default StyledInfoLinkList
