import CardContent from "@vertigis/react-ui/CardContent"
import { Service } from "../../features/services/types"
import StyledCard from "../styled/StyledCard"
import Typography from "@vertigis/react-ui/Typography"
import Box from "@vertigis/react-ui/Box"
import StyledProgressBar from "../styled/StyledProgressBar"
import CardActions from "@vertigis/react-ui/CardActions"
import Button from "@vertigis/react-ui/Button"
import Chip, { chipClasses } from "@vertigis/react-ui/Chip"
import Team from "@vertigis/react-ui/icons/Team"
import User from "@vertigis/react-ui/icons/User"
import ServiceIcon from "@vertigis/react-ui/icons/ServiceGp"
import AppIcon from "@vertigis/react-ui/icons/ServiceMap"
import { styled } from "@vertigis/react-ui/styles"
import StyledTooltip from "../styled/StyledTooltip"

interface Props {
  rs: Service
}

const iconSx = {
  color: "#adadad!important",
}

const StyledChip = styled(Chip)({
  [`&.${chipClasses.root}`]: {
    marginRight: "5px",
    marginBottom: "5px",
  },
})

const ServiceGridCard: React.FC<Props> = (props) => {
  const { rs } = props

  return (
    <StyledTooltip
      title={
        rs.link === "none" ? (
          <Typography>No link available yet</Typography>
        ) : null
      }
    >
      <StyledCard
        sx={{
          width: "400px",
          height: "100%",
          transition: "all .2s ease-in-out",
          ["&:hover"]:
            rs.link === "none"
              ? {}
              : {
                  boxShadow: 20,
                  cursor: "pointer",
                  transform: "scale(1.05)",
                },
        }}
        onClick={() => {
          if (rs.link === "none") {
            return
          }

          window
            .open(
              `https://${window.__RUNTIME_CONFIG__.VITE_APP_NAMESPACE}.westeurope.cloudapp.azure.com/${rs.link}`,
              "_blank",
            )
            ?.focus()
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <CardContent>
              <Typography
                variant="h1"
                sx={{
                  fontSize: "xx-large",
                  color: "#138943",
                  marginBottom: "10px",
                }}
              >
                {rs.name}
              </Typography>
              {window.__RUNTIME_CONFIG__.VITE_APP_DISPLAY_ADDITIONAL_INFO ===
                "true" && (
                <Box>
                  <StyledChip
                    icon={<Team sx={iconSx} />}
                    key={rs.team}
                    label={`Team: ${rs.team}`}
                  />
                  <StyledChip
                    icon={<User sx={iconSx} />}
                    key={rs.productOwner}
                    label={`PO: ${rs.productOwner}`}
                  />
                  <StyledChip
                    icon={
                      rs.category === "app" ? (
                        <AppIcon sx={iconSx} />
                      ) : (
                        <ServiceIcon sx={iconSx} />
                      )
                    }
                    label={rs.category === "app" ? "App" : "Service"}
                  />
                </Box>
              )}
              <Typography
                variant="subtitle1"
                sx={{
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                {rs.description}
              </Typography>
              {window.__RUNTIME_CONFIG__.VITE_APP_DISPLAY_ADDITIONAL_INFO ===
                "true" && (
                <>
                  <Typography
                    variant="h2"
                    sx={{
                      fontSize: "x-large",
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    Progress: {rs.progress}%
                  </Typography>
                  <Box>
                    <StyledProgressBar
                      variant="determinate"
                      value={rs.progress}
                    />
                  </Box>
                </>
              )}
            </CardContent>
          </Box>
        </Box>
      </StyledCard>
    </StyledTooltip>
  )
}

export default ServiceGridCard
