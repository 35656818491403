import Box from "@vertigis/react-ui/Box"
import Typography from "@vertigis/react-ui/Typography"
import { useTheme } from "@vertigis/react-ui/styles"
import { useMemo } from "react"
import { APPLICATIONS } from "./Nav/Navigation"
import { useParams } from "react-router-dom"

const CustomTitle: React.FC = () => {
  const theme = useTheme()

  const params = useParams()

  const selectedPage = useMemo(() => {
    if (!params.projectId || !(params.projectId in APPLICATIONS)) {
      return "home"
    }
    return params.projectId as keyof typeof APPLICATIONS
  }, [params])

  const texts = useMemo(() => {
    if (selectedPage === "home") {
      return {
        label: "Das Katasteramt in der Cloud",
        description:
          "Webbasierte Lösungen für die Verwaltung und Nutzung von Daten der amtlichen Vermessung.",
      }
    } else {
      return APPLICATIONS[selectedPage]
    }
  }, [params])

  return (
    <Box>
      <Box
        sx={{
          minHeight: "200px",
          width: "100v%",
          backgroundImage:
            "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/assets/berlin.png')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{ maxHeight: "4rem", mr: "20px" }}
            component="img"
            src="/assets/VertiGIS-Logo-White.svg"
          />
          <Typography
            variant="h1"
            sx={{ color: theme.palette.text.secondary, fontSize: "4rem" }}
          >
            Land Management
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          minHeight: "150px",
          width: "100%",
          color: theme.palette.text.secondary,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box textAlign={"center"}>
          <Typography variant="h2" sx={{ fontSize: "2.5rem", mb: "20px" }}>
            {texts.label}
          </Typography>
          <Typography variant="body1" sx={{ paddingInline: "20px" }}>
            {texts.description}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default CustomTitle
