import { styled, useTheme } from "@vertigis/react-ui/styles"
import Drawer from "@vertigis/react-ui/Drawer"
import List from "@vertigis/react-ui/List"
import Box from "@vertigis/react-ui/Box"
import Typography from "@vertigis/react-ui/Typography"
import ListItem from "@vertigis/react-ui/ListItem"
import ListItemButton from "@vertigis/react-ui/ListItemButton"
import { useNavigate } from "react-router-dom"
import StyledListItemButton from "./styled/StyledListItemButton"
import { useCallback, useState } from "react"

const StyledDrawer = styled(Drawer)({
  display: "block",
  ["& .MuiDrawer-paper"]: {
    background: "unset",
    borderRight: "unset",
    width: 200,
    boxSizing: "border-box",
  },
})

type CustomDrawerProps = {
  items: {
    key: string
    label: string
    iconSrc: string
  }[]
}

const CustomDrawer: React.FC<CustomDrawerProps> = (props) => {
  const { items } = props

  const [option, setOption] = useState("home")
  const nav = useNavigate()
  const theme = useTheme()

  const onSelect = useCallback(
    (selected?: string) => {
      setOption(selected ?? "home")
      nav(`/${selected ?? ""}`)
    },
    [setOption, nav],
  )

  return (
    <StyledDrawer
      sx={{
        backgroundColor: theme.specialColors.darkbg,
        height: "100vh",
        width: "200px",
        position: "fixed",
      }}
      variant="permanent"
      anchor="left"
    >
      <List>
        <StyledListItemButton
          selected={option === "home"}
          onClick={() => {
            onSelect()
          }}
          sx={{
            justifyContent: "center",
            marginBottom: "50px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              sx={{ width: 100, height: 100 }}
              alt="vertigis-logo"
              src={"/assets/vertigis.svg"}
            />
            <Typography
              sx={{
                color: theme.palette.text.secondary,
              }}
              variant="body1"
            >
              {window.__RUNTIME_CONFIG__.VITE_APP_NAMESPACE}
            </Typography>
          </Box>
        </StyledListItemButton>
        {items.map((i) => (
          <StyledListItemButton
            selected={option === i.key}
            sx={{
              justifyContent: "center",
              marginBottom: "25px",
              backgroundColor: theme.specialColors.darkbg,
              cursor: "pointer",
            }}
            key={i.key}
            onClick={() => onSelect(i.key)}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                sx={{ width: 60, height: 60 }}
                alt={`${i.key}-logo`}
                src={i.iconSrc}
              />
              <Typography
                sx={{
                  color: theme.palette.text.secondary,
                }}
                variant="body1"
              >
                {i.label}
              </Typography>
            </Box>
          </StyledListItemButton>
        ))}
      </List>
    </StyledDrawer>
  )
}

export default CustomDrawer
