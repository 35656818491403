import { styled, useTheme } from "@vertigis/react-ui/styles"
import LinearProgress, {
  LinearProgressProps,
  linearProgressClasses,
} from "@vertigis/react-ui/LinearProgress"
import { useMemo } from "react"

const StyledProgressBar = (props: LinearProgressProps) => {
  const theme = useTheme()

  const { value, variant } = props

  const color = useMemo(() => {
    if (variant !== "determinate" || !value) {
      return "hsl(144°, 76%, 31%)"
    }

    return `hsl(${value * 1.44}, 76%, 31%)`
  }, [variant, value])

  return (
    <LinearProgress
      sx={{
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor: theme.palette.grey[200],
        },
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: 5,
          backgroundColor: color,
        },
      }}
      {...props}
    />
  )
}

export default StyledProgressBar
